import React from "react";
import "./Heading.scss";

// ToDo: Change the name of this component name to Heading
function Heading() {
  return (
    <div className="heading">
      <h1 className="heading__title">¡Damos por finalizado el programa Becas PINN!</h1>
    </div>
  )
};

export default Heading;
